.episode-table-container {
    border: solid black 0.0625rem;
    border-left: none;
    border-right: none;
    border-bottom: none;
    overflow-y: scroll;
    max-height: 34.3rem;
    height: 34.3rem;
}

.table-row:hover {
    cursor: pointer;
}

.table-info {
    height: 3.125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color:rgba(220, 20, 60, 0) !important;
}

.pirate-x {
    height: 3.125rem;
    width: 3.125rem;
}

.table {
    margin-bottom: 0;
}

.ep-num {
    font-family: "Red Rose", cursive;
    color: crimson;
    font-size: 1rem;
}

.ep-title {
    font-size: 1rem;
}

.ep-date {
    font-family: "Red Rose", cursive;
    font-weight: 300;
}

@media(max-width: 1440px){
    .episode-table-container {
        border: solid black 0.0625rem;
        border-left: none;
        border-right: none;
        border-bottom: none;
        overflow-y: scroll;
        max-height: 30rem;
        height: 30rem;
    }
}

@media(min-width: 350px) and (max-width: 449px){
    .table-info {
        min-height: 9rem;
        
        display: flex;
        align-items: center;
        justify-content: center;
        background-color:rgba(220, 20, 60, 0) !important;
    }

}