.nav-logo {
  max-height: 4.6875rem;
  max-width: 4.6875rem;
}

.nav-logo:hover {
  cursor: pointer;
}

.logo-text {
  font-size: 2.1875rem;
  font-family: "Julee", cursive;
}

.logo-text:hover {
  cursor: pointer;
}

.nav-logo-container {
  height: 100%;
  width: 15.625rem;
  display: flex;
  align-items: center;
  transform: translateX(-100rem);
  animation: slideIn 0.5s forwards;
}

.test-nav-bar {
  height: 12.5rem;
  width: 100vw;
  position: fixed;
}

.inner-nav {
  display: grid;
  grid-template-columns: 6fr 1fr;
  height: 100%;
  margin-left: 10.9375rem;
  margin-right: 10.9375rem;
}

.nav-link {
  padding: 0;
  margin: 0;
  display: flex;
  align-self: center;
  color: gray;
  font-size: 1.125rem;
  font-family: "Roboto";
  overflow: visible;
}

.nav-link-container {
  width: 7.5rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateX(100rem);
  animation: slideIn 0.5s forwards;
}

.nav-link-container:nth-child(1) {
  animation-delay: 0s;
}

.nav-link-container:nth-child(2) {
  animation-delay: 0.5s;
}

.nav-link-container:nth-child(3) {
  animation-delay: 1s;
}

.nav-link:hover {
  cursor: pointer;
  color: crimson;
}

.hover-background {
  background-image: url("ink-splatter3.svg");
  background-repeat: no-repeat;
  background-size: 9.375rem;
  background-position: center 60%;
}

.nav-right-side {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

@media (min-width: 1440px) {
  .test-nav-bar {
    height: 10rem;
  }
}

@media (max-width: 950px) {
  .inner-nav {
    display: grid;
    grid-template-columns: 6fr 1fr;
    height: 100%;
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .test-nav-bar {
    height: 10rem;
    width: 100vw;
    position: fixed;
  }
  .nav-logo {
    max-height: 4rem;
    max-width: 4rem;
  }
  .nav-link {
    font-size: 2rem;
  }
}

@media (max-width: 899px) {
  .inner-nav {
    margin-left: 5rem;
    margin-right: 5rem;
  }
  .nav-logo {
    max-height: 4rem;
    max-width: 4rem;
  }
  .logo-text {
    font-size: 2rem;
    font-family: "Julee", cursive;
  }
}

@media (max-width: 849px) {
  .nav-logo {
    max-height: 4rem;
    max-width: 4rem;
  }
  .nav-logo:hover {
    cursor: pointer;
  }
  .logo-text {
    font-size: 2rem;
    font-family: "Julee", cursive;
  }
  .logo-text:hover {
    cursor: pointer;
  }
  .nav-logo-container {
    height: 100%;
    display: flex;
    align-items: center;
  }
  .test-nav-bar {
    height: 2.5rem;
    width: 100vw;
    position: fixed;
  }
  .inner-nav {
    display: grid;
    grid-template-columns: 6fr 1fr;
    height: 100%;
    margin-left: 4rem;
    margin-right: 4rem;
    margin-top: 4rem;
  }
  .nav-link {
    padding: 0;
    margin: 0;
    display: flex;
    align-self: center;
    color: gray;
    font-size: 1rem;
    font-family: "Roboto";
    overflow: visible;
  }
  .nav-link-container {
    width: 5.5rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .nav-link:hover {
    cursor: pointer;
    color: crimson;
  }
  .hover-background {
    background-image: url("ink-splatter3.svg");
    background-repeat: no-repeat;
    background-size: 5rem;
    background-position: center 60%;
  }
  .nav-right-side {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }
}

@media (max-width: 749px) {
  .nav-logo {
    max-height: 4rem;
    max-width: 4rem;
  }
  .nav-logo:hover {
    cursor: pointer;
  }
  .logo-text {
    font-size: 2rem;
    font-family: "Julee", cursive;
  }
  .logo-text:hover {
    cursor: pointer;
  }
  .nav-logo-container {
    height: 100%;
    display: flex;
    align-items: center;
  }
  .test-nav-bar {
    height: 2.5rem;
    width: 100vw;
    position: fixed;
  }
  .inner-nav {
    display: grid;
    grid-template-columns: 6fr 1fr;
    height: 100%;
    margin-left: 4rem;
    margin-right: 4rem;
    margin-top: 3rem;
  }
  .nav-link {
    padding: 0;
    margin: 0;
    display: flex;
    align-self: center;
    color: gray;
    font-size: 1rem;
    font-family: "Roboto";
    overflow: visible;
  }
  .nav-link-container {
    width: 5.5rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .nav-link:hover {
    cursor: pointer;
    color: crimson;
  }
  .hover-background {
    background-image: url("ink-splatter3.svg");
    background-repeat: no-repeat;
    background-size: 5rem;
    background-position: center 60%;
  }
  .nav-right-side {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }
}

@media (max-width: 950px) {
  .nav-link {
    padding: 0;
    margin: 0;
    display: flex;
    align-self: center;
    color: gray;
    font-size: 1.3rem;
    font-family: "Roboto";
    overflow: visible;
  }
}

@media (max-width: 849px) {
  .nav-logo {
    max-height: 4rem;
    max-width: 4rem;
  }
  .nav-logo:hover {
    cursor: pointer;
  }
  .logo-text {
    font-size: 2rem;
    font-family: "Julee", cursive;
  }
  .logo-text:hover {
    cursor: pointer;
  }
  .nav-logo-container {
    height: 100%;
    display: flex;
    align-items: center;
  }
  .test-nav-bar {
    height: 2.5rem;
    width: 100vw;
    position: fixed;
  }
  .inner-nav {
    display: grid;
    grid-template-columns: 6fr 1fr;
    height: 100%;
    margin-left: 4rem;
    margin-right: 4rem;
    margin-top: 3rem;
  }
  .nav-link {
    padding: 0;
    margin: 0;
    display: flex;
    align-self: center;
    color: gray;
    font-size: 1rem;
    font-family: "Roboto";
    overflow: visible;
  }
  .nav-link-container {
    width: 5.5rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .nav-link:hover {
    cursor: pointer;
    color: crimson;
  }
  .hover-background {
    background-image: url("ink-splatter3.svg");
    background-repeat: no-repeat;
    background-size: 5rem;
    background-position: center 60%;
  }
  .nav-right-side {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }
}

@media (max-width: 674px) {
  .nav-logo {
    max-height: 4rem;
    max-width: 4rem;
  }
  .nav-logo:hover {
    cursor: pointer;
  }
  .logo-text {
    font-size: 2rem;
    font-family: "Julee", cursive;
  }
  .logo-text:hover {
    cursor: pointer;
  }
  .nav-logo-container {
    height: 100%;
    display: flex;
    align-items: center;
  }
  .test-nav-bar {
    height: 2.5rem;
    width: 100vw;
    position: fixed;
  }
  .inner-nav {
    display: grid;
    grid-template-columns: 6fr 1fr;
    height: 100%;
    margin-left: 3rem;
    margin-right: 3rem;
    margin-top: 3rem;
  }
  .nav-link {
    padding: 0;
    margin: 0;
    display: flex;
    align-self: center;
    color: gray;
    font-size: 1rem;
    font-family: "Roboto";
    overflow: visible;
  }
  .nav-link-container {
    width: 5.5rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .nav-link:hover {
    cursor: pointer;
    color: crimson;
  }
  .hover-background {
    background-image: url("ink-splatter3.svg");
    background-repeat: no-repeat;
    background-size: 5rem;
    background-position: center 60%;
  }
  .nav-right-side {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }
}

@media (max-width: 579px) {
  .nav-logo {
    max-height: 4rem;
    max-width: 4rem;
  }
  .nav-logo:hover {
    cursor: pointer;
  }
  .logo-text {
    font-size: 2rem;
    font-family: "Julee", cursive;
  }
  .logo-text:hover {
    cursor: pointer;
  }
  .nav-logo-container {
    height: 100%;
    display: flex;
    align-items: center;
  }
  .test-nav-bar {
    height: 2.5rem;
    width: 100vw;
    position: fixed;
  }
  .inner-nav {
    display: grid;
    grid-template-columns: 6fr 1fr;
    height: 100%;
    margin-left: 3rem;
    margin-right: 3rem;
    margin-top: 3rem;
  }
  .nav-link {
    padding: 0;
    margin: 0;
    display: flex;
    align-self: center;
    color: gray;
    font-size: 1rem;
    font-family: "Roboto";
    overflow: visible;
  }
  .nav-link-container {
    width: 5.5rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .nav-link:hover {
    cursor: pointer;
    color: crimson;
  }
  .hover-background {
    background-image: url("ink-splatter3.svg");
    background-repeat: no-repeat;
    background-size: 5rem;
    background-position: center 60%;
  }
  .nav-right-side {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }
}

@media (max-width: 499px) {
  .nav-logo {
    max-height: 4rem;
    max-width: 4rem;
  }
  .nav-logo:hover {
    cursor: pointer;
  }
  .logo-text {
    font-size: 2rem;
    font-family: "Julee", cursive;
  }
  .logo-text:hover {
    cursor: pointer;
  }
  .nav-logo-container {
    height: 100%;
    display: flex;
    align-items: center;
    width: 9rem;
  }
  .test-nav-bar {
    height: 2.5rem;
    width: 100vw;
    position: fixed;
  }
  .inner-nav {
    display: grid;
    grid-template-columns: 3fr 2fr;
    height: 100%;
    margin-left: 3rem;
    margin-right: 3rem;
    margin-top: 3rem;
  }
  .nav-link {
    padding: 0;
    margin: 0;
    display: flex;
    align-self: center;
    color: gray;
    font-size: 1rem;
    font-family: "Roboto";
    overflow: visible;
  }
  .nav-link-container {
    width: 5rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .nav-link:hover {
    cursor: pointer;
    color: crimson;
  }
  .hover-background {
    background-image: url("ink-splatter3.svg");
    background-repeat: no-repeat;
    background-size: 5rem;
    background-position: center 60%;
  }
  .nav-right-side {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }
}

@media (max-width: 410px) {
  .nav-logo {
    max-height: 3.3rem;
    max-width: 3.3rem;
  }
  .nav-logo:hover {
    cursor: pointer;
  }
  .logo-text {
    font-size: 1.3rem;
    font-family: "Julee", cursive;
  }
  .logo-text:hover {
    cursor: pointer;
  }
  .nav-logo-container {
    height: 100%;
    display: flex;
    align-items: center;
    width: 7rem;
  }
  .test-nav-bar {
    height: 2.5rem;
    width: 100vw;
    position: fixed;
  }
  .inner-nav {
    display: grid;
    grid-template-columns: 3fr 2fr;
    height: 100%;
    margin-left: 2.5rem;
    margin-right: 2.5rem;
    margin-top: 3.4rem;
  }
  .nav-link {
    padding: 0;
    margin: 0;
    display: flex;
    align-self: center;
    color: gray;
    font-size: 0.8rem;
    font-family: "Roboto";
    overflow: visible;
  }
  .nav-link-container {
    width: 4.5rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .nav-link:hover {
    cursor: pointer;
    color: crimson;
  }
  .hover-background {
    background-image: url("ink-splatter3.svg");
    background-repeat: no-repeat;
    background-size: 5rem;
    background-position: center 60%;
  }
  .nav-right-side {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }
}

@media (max-width: 320px) {
  .nav-logo {
    max-height: 3.3rem;
    max-width: 3.3rem;
  }
  .nav-logo:hover {
    cursor: pointer;
  }
  .logo-text {
    font-size: 1.3rem;
    font-family: "Julee", cursive;
  }
  .logo-text:hover {
    cursor: pointer;
  }
  .nav-logo-container {
    height: 100%;
    display: flex;
    align-items: center;
    width: 7rem;
  }
  .test-nav-bar {
    height: 2.5rem;
    width: 100vw;
    position: fixed;
  }
  .inner-nav {
    display: grid;
    grid-template-columns: 3fr 2fr;
    height: 100%;
    margin-left: 2.5rem;
    margin-right: 2.5rem;
    margin-top: 3.4rem;
  }
  .nav-link {
    padding: 0;
    margin: 0;
    display: flex;
    align-self: center;
    color: gray;
    font-size: 0.8rem;
    font-family: "Roboto";
    overflow: visible;
  }
  .nav-link-container {
    width: 4rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .nav-link:hover {
    cursor: pointer;
    color: crimson;
  }
  .hover-background {
    background-image: url("ink-splatter3.svg");
    background-repeat: no-repeat;
    background-size: 5rem;
    background-position: center 60%;
  }
  .nav-right-side {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }
}

.burger_hamburger__21cuQ {
  margin: 0rem !important;
  position: fixed !important;
  right: 6.5rem;
  top: 3.9rem;
  z-index: 10000;
  outline: none !important;
}

.nav-slide-wrapper {
  background-color: darkgray;
  height: 100vh;
  width: 17rem;
  position: absolute;
  z-index: 1000;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  overflow: hidden;
  transition: width 1s ease-in-out, height 0s;
}

.nav-slide-closed {
  background-color: darkgray;
  display: flex;
  align-items: center;
  flex-direction: column;
  line-height: 0;
  width: 0rem;
  height: 0rem;
}

.nav-slide-inner {
  height: 50%;
  text-align: center;
  display: flex;
  flex-flow: column;
  grid-row-gap: 1.5rem;
}

.nav-slide-inner-hidden {
  display: none;
}

.nav-slide-link {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-family: "Roboto";
  font-size: 1.5rem;
  border: solid black 1px;
  background-color: whitesmoke;
  border-radius: 0.6rem;
  width: 7.5rem;
  height: 3rem;
  box-shadow: 0.125rem 0.125rem 0.375rem 0.0925rem rgba(0, 0, 0, 0.651);
}

.nav-slide-link:hover {
  color: crimson;
}

@keyframes slideIn {
  from {
  }
  to {
    transform: translateX(0);
  }
}
