@media (min-width: 37.5625rem){

    .watchlist-container {
        width: 100vw;
        height: 100vh;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        min-width: 100vw;
        padding-left: 10.9375rem;
        padding-right: 10.9375rem;
      
    }

    .watchlist-title {
        font-size: 1.875rem;
        font-family: "Red Rose", cursive;
        padding-top: 11.25rem;
        position: fixed;
        color: crimson;
        font-weight: 400;
    }

    .watchlist-inner {
        position: relative;
        z-index: 100;
        max-height: 100vh;
        padding: 3.125rem;
        flex-flow: column wrap;
        margin-top: 13.75rem;
        grid-row-gap: 3.125rem;
        border-radius: 0.9375rem;
        margin-bottom: 4.6875rem;
        overflow: scroll;
        width: 100%;
        background-color: rgb(233, 204, 210);
        border: solid 0.0625rem crimson;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        grid-column-gap: 3.125rem;
        box-shadow: 0.125rem 0.125rem 0.375rem 0.0625rem hsla(0, 0%, 0%, 0.493);
    }

    .styled-text {
        font-family: "Roboto";
        font-weight: 400;
        font-size: 1.875rem;
        color: crimson;
    }

    .watchlist-wrapper {
        max-height: 100vh;
        max-width: 100vw;
        overflow: hidden;
        background-color: whitesmoke;
        background-image: url('./ink-splatter2.svg');
        background-size: 31.25rem;
        background-repeat: no-repeat;
        background-position: 108% 25%;
    }


    .ink-splatter-image {
        transform: rotate(120deg);
        position: absolute;
        z-index: 0;
        height: 25rem;
        width: 25rem;
        margin-top: -25rem;
        margin-left: -3.125rem;
    }
}

@media (max-width: 700px){
    .watchlist-container {
        width: 100vw;
        height: 100vh;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        min-width: 100vw;
        padding-left: 1rem;
        padding-right: 1rem;
      
    }

    .watchlist-title {
        font-size: 1.875rem;
        font-family: "Red Rose", cursive;
        padding-top: 8rem;
        position: fixed;
        color: crimson;
        font-weight: 400;
    }

    .watchlist-inner {
        position: relative;
        z-index: 100;
        max-height: 35rem;
        padding: 1rem;
        flex-flow: column wrap;
        margin-top: 12.75rem;
        grid-row-gap: 3.125rem;
        border-radius: 0.9375rem;
        margin-bottom: 4.6875rem;
        overflow: scroll;
        width: 100%;
        background-color: rgb(233, 204, 210);
        border: solid 0.0625rem crimson;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        grid-column-gap: 1.5rem;
        box-shadow: 0.125rem 0.125rem 0.375rem 0.0625rem hsla(0, 0%, 0%, 0.493);
    }

    .styled-text {
        font-family: "Roboto";
        font-weight: 400;
        font-size: 1.875rem;
        color: crimson;
    }

    .watchlist-wrapper {
        max-height: 100vh;
        max-width: 100vw;
        overflow: hidden;
        background-color: whitesmoke;
        
    }


    .ink-splatter-image {
        position: absolute;
        z-index: 0;
        height: 20rem;
        width: 20rem;
        margin-top: -19rem;
        margin-left: -10.125rem;
    }
}


