@media (min-width: 1441px) {
  .show-progress-container {
    max-height: 100vh;
    max-width: 100vw;
    text-align: center;

    position: fixed;
    border: 1px solid black;
    border-radius: 10px;
    background-color: whitesmoke;

    -webkit-box-shadow: -5px 5px 10px 8px rgba(0, 0, 0, 0.699);
    -moz-box-shadow: -5px 5px 10px 8px rgb(0, 0, 0, 0.699);
    box-shadow: -5px 5px 10px 5px rgb(0, 0, 0, 0.699);
  }

  .show-progress-wrapper {
    padding-top: 7%;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("Galleon_Sail.svg"), url("ink-splatter5.svg");
    background-size: 900px, 500px;
    background-repeat: no-repeat;
    background-position: 150% 100%, 0% 23%;
  }

  .select-a-season {
    color: crimson;
    font-family: "Roboto";
    font-size: 20px;
    text-shadow: 1px 1px whitesmoke;
  }

  .show-progress-inner {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .progress-inner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("ink-splatter3.svg");
    background-repeat: no-repeat;
    background-size: 900px;
    background-position: 80% 40%;
  }

  .progress-inner {
    display: flex;
    justify-content: space-evenly;
    font-family: "Roboto";
    font-size: 20px;
    font-weight: 300;
    width: 100%;
  }

  .progress-inner p {
    margin: 0;
    color: crimson;
    background-color: rgba(245, 245, 245, 0.726);
    border-radius: 0.2rem;
  }

  .show-box-inner {
    padding: 25px 25px 25px 25px;
    border: solid black 1px;
    border-left: none;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
    height: 400px;
    width: 650px;
  }

  .episode-inner {
    padding: 20px 20px 20px 20px;
    border: solid black 1px;
    border-left: none;
    border-bottom: none;
    height: 480px;
  }

  .watchlist-poster {
    height: 350px;
    width: 250px;
    border: solid black 1px;
    box-shadow: -2px 2px 10px 1px rgb(0, 0, 0);
  }

  .show-box-details {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .current-progress {
    border: solid black 1px;
    border-bottom: none;
    border-left: none;
    border-right: none;
    height: 100px;
    display: grid;
    grid-template-columns: 1fr 4fr;
  }

  .progress-divider {
    font-family: "Red Rose", cursive;
    font-weight: 300;
    font-size: 20px;
  }

  .progress-left {
    border-right: solid 1px black;
    width: 250px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .season-container {
    text-align: center;
    padding-top: 22px;
    border: solid black 1px;
    border-top: none;
    border-left: none;
    border-bottom: none;
    height: 80px;
  }

  .btn-primary {
    width: 300px !important;
    background-color: gray !important;
    border-color: white !important;
  }

  .dropdown-menu {
    width: 300px !important;
    max-height: 400px;
    overflow: scroll;
  }

  .watchlist-details-header {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 0;
  }

  .watchlist-header-container {
    padding-bottom: 1em;
  }

  .watchlist-show-year {
    color: gray;
  }

  .watchlist-buttons-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 200px;
  }

  .watchlist-details-bottom {
    display: flex;
    justify-content: space-between;
  }

  .total-seasons {
    width: 110px;
    height: 50px;
    display: flex;
    align-items: center;
  }

  .ui.small.modal {
    position: relative;
    max-height: 200px;
    top: none;
    left: none;
  }
}

@media (min-width: 1440px) and (max-width: 1440px) {
  .show-progress-container {
    max-height: 100vh;
    max-width: 100vw;
    text-align: center;

    position: fixed;
    border: 0.0625rem solid black;
    border-radius: 0.625rem;
    background-color: whitesmoke;

    -webkit-box-shadow: -0.3125rem 0.3125rem 0.625rem 0.5rem
      rgba(0, 0, 0, 0.699);
    -moz-box-shadow: -0.3125rem 0.3125rem 0.625rem 0.5rem rgb(0, 0, 0, 0.699);
    box-shadow: -0.3125rem 0.3125rem 0.625rem 0.3125rem rgb(0, 0, 0, 0.699);
  }

  .show-progress-wrapper {
    padding-top: 7%;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("Galleon_Sail.svg"), url("ink-splatter5.svg");
    background-size: 56.25rem, 31.25rem;
    background-repeat: no-repeat;
    background-position: 150% 100%, 0% 23%;
  }

  .select-a-season {
    color: crimson;
    font-family: "Roboto";
    font-size: 1.25rem;
    text-shadow: 0.0625rem 0.0625rem whitesmoke;
  }

  .show-progress-inner {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .progress-inner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("ink-splatter3.svg");
    background-repeat: no-repeat;
    background-size: 56.25rem;
    background-position: 80% 40%;
  }

  .progress-inner {
    display: flex;
    justify-content: space-evenly;
    font-family: "Roboto";
    font-size: 1.25rem;
    font-weight: 300;
    width: 100%;
  }

  .progress-inner p {
    margin: 0;
    color: crimson;
    background-color: rgba(245, 245, 245, 0.726);
    border-radius: 0.2rem;
  }

  .show-box-inner {
    padding: 1.5625rem 1.5625rem 1.5625rem 1.5625rem;
    border: solid black 0.0625rem;
    border-left: none;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 0.625rem;
    height: 25rem;
    width: 40.625rem;
  }

  .episode-inner {
    padding: 1.25rem 1.25rem 1.25rem 1.25rem;
    border: solid black 0.0625rem;
    border-left: none;
    border-bottom: none;
    height: 30rem;
  }

  .watchlist-poster {
    height: 21.875rem;
    width: 15.625rem;
    border: solid black 0.0625rem;
    box-shadow: -0.125rem 0.125rem 0.625rem 0.0625rem rgb(0, 0, 0);
  }

  .show-box-details {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .current-progress {
    border: solid black 0.0625rem;
    border-bottom: none;
    border-left: none;
    border-right: none;
    height: 6.25rem;
    display: grid;
    grid-template-columns: 1fr 4fr;
  }

  .progress-divider {
    font-family: "Red Rose", cursive;
    font-weight: 300;
    font-size: 1.25rem;
  }

  .progress-left {
    border-right: solid 0.0625rem black;
    width: 15.625rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .season-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0rem;
    text-align: center;
    border: solid black 0.0625rem;
    border-top: none;
    border-left: none;
    border-bottom: none;
    height: 5rem;
  }

  .btn-primary {
    height: 3rem;
    width: 25.75rem !important;
    background-color: gray !important;
    border-color: white !important;
  }

  .dropdown-menu {
    width: 18.75rem !important;
    max-height: 25rem;
    overflow: scroll;
  }

  .watchlist-details-header {
    font-weight: bold;
    font-size: 1rem;
    margin-bottom: 0;
  }

  .watchlist-header-container {
    padding-bottom: 1em;
  }

  .watchlist-show-year {
    color: gray;
  }

  .watchlist-buttons-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 12.5rem;
  }

  .watchlist-details-bottom {
    display: flex;
    justify-content: space-between;
  }

  .total-seasons {
    width: 9rem;
    height: 3.125rem;
    display: flex;
    align-items: center;
  }

  .ui.small.modal {
    position: relative;
    max-height: 12.5rem;
    top: none;
    left: none;
  }

  .ui.modal > .actions {
    padding: 0.8rem;
  }

  .ui.primary.button {
    background-color: rgba(220, 20, 60, 0.877) !important;
  }

  .ui.primary.button:hover {
    background-color: rgb(220, 20, 60) !important;
  }
}

@media (min-width: 800px) and (max-width: 1439px) {
  .show-progress-container {
    margin-top: 15%;
    max-width: 50rem;
    text-align: center;
    border: 0.0625rem solid black;
    border-radius: 0.625rem;
    background-color: whitesmoke;

    -webkit-box-shadow: -0.3125rem 0.3125rem 0.625rem 0.5rem
      rgba(0, 0, 0, 0.699);
    -moz-box-shadow: -0.3125rem 0.3125rem 0.625rem 0.5rem rgb(0, 0, 0, 0.699);
    box-shadow: -0.3125rem 0.3125rem 0.625rem 0.3125rem rgb(0, 0, 0, 0.699);
  }

  .show-progress-wrapper {
    background-color: whitesmoke;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("Galleon_Sail.svg"), url("ink-splatter5.svg");
    background-size: 0rem, 40rem;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: 150% 100%, 0% 26%;
  }

  .select-a-season {
    color: crimson;
    font-family: "Roboto";
    font-size: 1.25rem;
    text-shadow: 0.0625rem 0.0625rem whitesmoke;
  }

  .show-progress-inner {
    display: grid;
    grid-template-rows: 1fr 1fr;
  }

  .progress-inner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("ink-splatter3.svg");
    background-repeat: no-repeat;
    background-size: 56.25rem;
    background-position: 36% 40%;
  }

  .progress-inner {
    display: flex;
    justify-content: space-evenly;
    font-family: "Roboto";
    font-size: 1.25rem;
    font-weight: 300;
    width: 100%;
  }

  .progress-inner p {
    margin: 0;
    color: crimson;
    background-color: rgba(245, 245, 245, 0.726);
    border-radius: 0.2rem;
  }

  .show-box-inner {
    padding: 1.5625rem 1.5625rem 1.5625rem 1.5625rem;
    border: solid black 0.0625rem;
    border-right: none;
    border-left: none;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 0.625rem;
    height: 25rem;
    width: 100%;
  }

  .episode-inner {
    padding: 1.25rem 1.25rem 1.25rem 1.25rem;
    border: solid black 0.0625rem;
    border-left: none;
    border-bottom: none;
    height: 30rem;
  }

  .watchlist-poster {
    height: 21.875rem;
    width: 15.625rem;
    border: solid black 0.0625rem;
    box-shadow: -0.125rem 0.125rem 0.625rem 0.0625rem rgb(0, 0, 0);
  }

  .show-box-details {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .current-progress {
    border: solid black 0.0625rem;
    border-bottom: none;
    border-left: none;
    border-right: none;
    height: 6.25rem;
    display: grid;
    grid-template-columns: 1fr 4fr;
  }

  .progress-divider {
    font-family: "Red Rose", cursive;
    font-weight: 300;
    font-size: 1.25rem;
  }

  .progress-left {
    border-right: solid 0.0625rem black;
    width: 15.625rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .season-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0rem;
    text-align: center;

    height: 15%;
  }

  .dropup button {
    width: 20rem;
  }

  .btn-primary {
    height: 3rem;
    width: 25.75 !important;
    background-color: gray !important;
    border-color: white !important;
  }

  .dropdown-menu {
    width: 18.75rem !important;
    max-height: 25rem;
    overflow: scroll;
  }

  .watchlist-details-header {
    font-weight: bold;
    font-size: 1rem;
    margin-bottom: 0;
  }

  .watchlist-header-container {
    padding-bottom: 1em;
  }

  .watchlist-show-year {
    color: gray;
  }

  .watchlist-buttons-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 12.5rem;
  }

  .watchlist-details-bottom {
    display: flex;
    justify-content: space-between;
  }

  .total-seasons {
    width: 6.875rem;
    height: 3.125rem;
    display: flex;
    align-items: center;
  }

  .ui.small.modal {
    position: relative;
    max-height: 12.5rem;
    top: none;
    left: none;
  }

  .ui.modal > .actions {
    padding: 0.8rem;
  }

  .ui.primary.button {
    background-color: rgba(220, 20, 60, 0.877) !important;
  }

  .ui.primary.button:hover {
    background-color: rgb(220, 20, 60) !important;
  }
}

@media (min-width: 760px) and (max-width: 799px) {
  .ui.small.modal {
    position: relative;
    max-height: 12.5rem !important;
    top: none;
    left: none;
  }
}

@media (min-width: 600px) and (max-width: 799px) {
  .show-progress-container {
    margin-top: 22%;
    max-width: 40rem;
    text-align: center;
    border: 0.0625rem solid black;
    border-radius: 0.625rem;
    background-color: whitesmoke;

    -webkit-box-shadow: -0.3125rem 0.3125rem 0.625rem 0.5rem
      rgba(0, 0, 0, 0.699);
    -moz-box-shadow: -0.3125rem 0.3125rem 0.625rem 0.5rem rgb(0, 0, 0, 0.699);
    box-shadow: -0.3125rem 0.3125rem 0.625rem 0.3125rem rgb(0, 0, 0, 0.699);
  }

  .show-progress-wrapper {
    background-color: whitesmoke;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("Galleon_Sail.svg"), url("ink-splatter5.svg");
    background-size: 0rem, 31.25rem;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: 150% 100%, 0% 25%;
  }

  .select-a-season {
    color: crimson;
    font-family: "Roboto";
    font-size: 1.25rem;
    text-shadow: 0.0625rem 0.0625rem whitesmoke;
  }

  .show-progress-inner {
    display: grid;
    grid-template-rows: 1fr 1fr;
  }

  .progress-inner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("ink-splatter3.svg");
    background-repeat: no-repeat;
    background-size: 56.25rem;
    background-position: 40% 40%;
  }

  .progress-inner {
    display: flex;
    justify-content: space-evenly;
    font-family: "Roboto";
    font-size: 1.25rem;
    font-weight: 300;
    width: 100%;
  }

  .progress-inner p {
    margin: 0;
    color: crimson;
    background-color: rgba(245, 245, 245, 0.726);
    border-radius: 0.2rem;
  }

  .show-box-inner {
    padding: 1.5625rem 1.5625rem 1.5625rem 1.5625rem;
    border: solid black 0.0625rem;
    border-right: none;
    border-left: none;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 0.625rem;
    height: 25rem;
    width: 100%;
  }

  .episode-inner {
    padding: 1.25rem 1.25rem 1.25rem 1.25rem;
    border: solid black 0.0625rem;
    border-left: none;
    border-bottom: none;
    height: 30rem;
  }

  .watchlist-poster {
    height: 21.875rem;
    width: 15.625rem;
    border: solid black 0.0625rem;
    box-shadow: -0.125rem 0.125rem 0.625rem 0.0625rem rgb(0, 0, 0);
  }

  .show-box-details {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .current-progress {
    border: solid black 0.0625rem;
    border-bottom: none;
    border-left: none;
    border-right: none;
    height: 6.25rem;
    display: grid;
    grid-template-columns: 1fr 4fr;
  }

  .progress-divider {
    font-family: "Red Rose", cursive;
    font-weight: 300;
    font-size: 1.25rem;
  }

  .progress-left {
    border-right: solid 0.0625rem black;
    width: 15.625rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .season-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0rem;
    text-align: center;

    height: 15%;
  }

  .dropup button {
    width: 20rem;
  }

  .btn-primary {
    height: 3rem;
    width: 25.75 !important;
    background-color: gray !important;
    border-color: white !important;
  }

  .dropdown-menu {
    width: 18.75rem !important;
    max-height: 25rem;
    overflow: scroll;
  }

  .watchlist-details-header {
    font-weight: bold;
    font-size: 1rem;
    margin-bottom: 0;
  }

  .watchlist-header-container {
    padding-bottom: 1em;
  }

  .watchlist-show-year {
    color: gray;
  }

  .watchlist-buttons-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 12.5rem;
  }

  .watchlist-details-bottom {
    display: flex;
    justify-content: space-between;
  }

  .total-seasons {
    width: 6.875rem;
    height: 3.125rem;
    display: flex;
    align-items: center;
  }

  .ui.small.modal {
    position: relative;
    max-height: 11rem;
    top: none;
    left: none;
  }

  .ui.modal > .actions {
    padding: 0.8rem;
  }

  .ui.primary.button {
    background-color: rgba(220, 20, 60, 0.877) !important;
  }

  .ui.primary.button:hover {
    background-color: rgb(220, 20, 60) !important;
  }
}

@media (min-width: 450px) and (max-width: 599px) {
  .show-progress-container {
    margin-top: 30%;
    max-width: 30rem;
    text-align: center;
    border: 0.0625rem solid black;
    border-radius: 0.625rem;
    background-color: whitesmoke;
    -webkit-box-shadow: -0.3125rem 0.3125rem 0.625rem 0.5rem
      rgba(0, 0, 0, 0.699);
    -moz-box-shadow: -0.3125rem 0.3125rem 0.625rem 0.5rem rgb(0, 0, 0, 0.699);
    box-shadow: -0.3125rem 0.3125rem 0.625rem 0.3125rem rgb(0, 0, 0, 0.699);
  }

  .rating-icon {
    height: 3rem;
    width: 3rem;
  }

  .show-progress-wrapper {
    background-color: whitesmoke;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("Galleon_Sail.svg"), url("ink-splatter5.svg");
    background-size: 0rem, 31.25rem;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: 150% 100%, 0% 23%;
  }

  .select-a-season {
    color: crimson;
    font-family: "Roboto";
    font-size: 1.25rem;
    text-shadow: 0.0625rem 0.0625rem whitesmoke;
  }

  .show-progress-inner {
    display: grid;
    grid-template-rows: 1fr 1fr;
  }

  .progress-inner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("ink-splatter3.svg");
    background-repeat: no-repeat;
    background-size: 56.25rem;
    background-position: 40% 38%;
  }

  .progress-inner {
    display: flex;
    justify-content: space-evenly;
    font-family: "Roboto";
    font-size: 1.25rem;
    font-weight: 300;
    width: 100%;
  }

  .progress-inner p {
    margin: 0;
    color: crimson;
    background-color: rgba(245, 245, 245, 0.726);
    border-radius: 0.2rem;
  }

  .show-box-inner {
    padding: 1.5625rem 1.5625rem 1.5625rem 1.5625rem;
    border: solid black 0.0625rem;
    border-right: none;
    border-left: none;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 0.625rem;
    height: 25rem;
    width: 100%;
  }

  .episode-inner {
    padding: 1.25rem 1.25rem 1.25rem 1.25rem;
    border: solid black 0.0625rem;
    border-left: none;
    border-bottom: none;
    height: 30rem;
  }

  .watchlist-poster {
    height: 21.875rem;
    width: 15.625rem;
    border: solid black 0.0625rem;
    box-shadow: -0.125rem 0.125rem 0.625rem 0.0625rem rgb(0, 0, 0);
  }

  .show-box-details {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .current-progress {
    border: solid black 0.0625rem;
    border-bottom: none;
    border-left: none;
    border-right: none;
    height: 6.25rem;
    display: grid;
    grid-template-columns: 1fr 4fr;
  }

  .progress-divider {
    font-family: "Red Rose", cursive;
    font-weight: 300;
    font-size: 1.25rem;
  }

  .progress-left {
    border-right: solid 0.0625rem black;
    width: 15.625rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .season-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0rem;
    text-align: center;

    height: 15%;
  }

  .dropup button {
    width: 20rem;
  }

  .btn-primary {
    height: 3rem;
    width: 25.75 !important;
    background-color: gray !important;
    border-color: white !important;
  }

  .dropdown-menu {
    width: 18.75rem !important;
    max-height: 25rem;
    overflow: scroll;
  }

  .watchlist-details-header {
    font-weight: bold;
    font-size: 1rem;
    margin-bottom: 0;
  }

  .watchlist-header-container {
    padding-bottom: 1em;
  }

  .watchlist-show-year {
    color: gray;
  }

  .watchlist-buttons-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 12.5rem;
  }

  .watchlist-details-bottom {
    display: flex;
    justify-content: space-between;
  }

  .total-seasons {
    width: 6.875rem;
    height: 3.125rem;
    display: flex;
    align-items: center;
  }

  .ui.small.modal {
    position: relative;
    max-height: 11rem;
    top: none;
    left: none;
  }

  .ui.modal > .actions {
    padding: 0.8rem;
  }

  .ui.primary.button {
    background-color: rgba(220, 20, 60, 0.877) !important;
  }

  .ui.primary.button:hover {
    background-color: rgb(220, 20, 60) !important;
  }
}

@media (min-width: 350px) and (max-width: 449px) {
  .show-progress-container {
    margin-top: 35%;
    margin-bottom: 3rem;
    max-width: 20rem;
    text-align: center;
    border: 0.0625rem solid black;
    border-radius: 0.625rem;
    background-color: whitesmoke;

    -webkit-box-shadow: 0.125rem 0.125rem 0.375rem 0.0925rem
      rgba(0, 0, 0, 0.651);
    -moz-box-shadow: 0.125rem 0.125rem 0.375rem 0.0925rem rgba(0, 0, 0, 0.651);
    box-shadow: 0.125rem 0.125rem 0.375rem 0.0925rem rgba(0, 0, 0, 0.651);
  }

  .rating-icon {
    height: 3rem;
    width: 3rem;
  }

  .show-progress-wrapper {
    background-color: whitesmoke;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("Galleon_Sail.svg"), url("ink-splatter5.svg");
    background-size: 0rem, 35rem;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: 10% 100%, 20% 55%;
  }

  .select-a-season {
    color: crimson;
    font-family: "Roboto";
    font-size: 1.25rem;
    text-shadow: 0.0625rem 0.0625rem whitesmoke;
  }

  .show-progress-inner {
    display: flex;
    flex-direction: column;
  }

  .progress-inner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("ink-splatter3.svg");
    background-repeat: no-repeat;
    background-size: 56.25rem;
    background-position: 40% 38%;
  }

  .progress-inner {
    display: flex;
    justify-content: space-evenly;
    font-family: "Roboto";
    font-size: 1.25rem;
    font-weight: 300;
    width: 100%;
  }

  .progress-inner p {
    margin: 0;
    color: crimson;
    font-size: 18px;
    background-color: rgba(245, 245, 245, 0.726);
    border-radius: 0.2rem;
  }

  .show-box-inner {
    padding: 1.5625rem 1.5625rem 1.5625rem 1.5625rem;
    border: solid black 0.0625rem;
    border-right: none;
    border-left: none;
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-column-gap: 0.625rem;

    width: 100%;
  }

  .episode-inner {
    padding: 1.25rem 1.25rem 1.25rem 1.25rem;
    border: solid black 0.0625rem;
    border-left: none;
    border-bottom: none;
    height: 30rem;
  }

  .watchlist-poster {
    height: 21.875rem;
    width: 15.625rem;
    border: solid black 0.0625rem;
    box-shadow: -0.125rem 0.125rem 0.625rem 0.0625rem rgb(0, 0, 0);
  }

  .show-box-details {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
    padding-top: 2rem;
  }

  .current-progress {
    border: solid black 0.0625rem;
    border-bottom: none;
    border-left: none;
    border-right: none;
    height: 6.25rem;
    display: grid;
    grid-template-columns: 1fr 4fr;
  }

  .progress-divider {
    font-family: "Red Rose", cursive;
    font-weight: 300;
    font-size: 1.25rem;
  }

  .progress-left {
    border-right: solid 0.0625rem black;
    width: 8rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dropup button {
    margin-top: 1rem;
    margin-bottom: 0.8rem;
    width: 14rem;
    height: 3rem;
  }

  .btn-primary {
    height: 3rem;
    width: 25.75 !important;
    background-color: gray !important;
    border-color: white !important;
  }

  .dropdown-menu {
    width: 18.75rem !important;
    max-height: 25rem;
    overflow: scroll;
  }

  .watchlist-details-header {
    font-weight: bold;
    font-size: 1rem;
    margin-bottom: 0;
  }

  .watchlist-show-year {
    color: gray;
  }

  .watchlist-buttons-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 12.5rem;
  }

  .watchlist-details-bottom {
    display: flex;
    justify-content: space-between;
  }

  .total-seasons {
    width: 8rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .total-seasons p {
    margin-top: -0.3rem;
  }

  .ui.small.modal {
    position: relative;
    max-height: 12.5rem;
    top: none;
    left: none;
  }

  .ui.modal > .actions {
    padding: 0.8rem;
  }

  .ui.primary.button {
    background-color: rgba(220, 20, 60, 0.877) !important;
  }

  .ui.primary.button:hover {
    background-color: rgb(220, 20, 60) !important;
  }

  .modal-top {
    height: 25rem !important;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    color: rgba(0, 0, 0, 0.726);
  }

  .ui.centered.image {
    display: none !important;
    visibility: hidden !important;
  }

  .modal-description {
    display: grid !important;
    grid-template-rows: 2fr 1fr !important;
  }

  .spyglass-container {
    width: 11rem !important;
  }

  .bold-description {
    margin: 0rem !important;
    color: black;
  }

  .modal-total-seasons {
    padding-top: 0rem !important;
    height: 1rem !important;
    font-weight: 300;
    font-family: "Red Rose", cursive;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}

@media (min-width: 410px) and (max-width: 449px) {
  .ui.small.modal {
    position: relative;
    max-height: 11rem !important;
    top: none;
    left: none;
  }
}

.update-show-button {
  width: 100%;
}

.update-show-description {
  color: gray;
  font-family: "Red Rose", cursive;
  font-weight: 300;
}

.update-show-dropdown {
  .visible.menu.transition {
    max-height: 9.5rem !important;
  }
}

.update-modal-content {
  padding: 0rem 4rem 4rem 4rem;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.modal-inner {
  max-width: 50rem;
}

.visible.menu.transition {
  max-height: 10.5rem !important;
}
