@media (min-width: 1441px){

.moviePosterContainer {
  
  border: solid black 0.125rem;
  border-radius: 0.3125rem;
  overflow: hidden;
  width: 11.375rem;
  height: 15rem;
  max-height: 15rem;
  max-width: 11.375rem;
}



.moviePosterContainer img {
  border-radius: 0.3125rem;
  width: 11.375rem;
  height: 15rem;
  max-height: 15rem;
  max-width: 11.375rem;

}

.react-card-front {
  border-radius: 0.3125rem;
  background-color: rgba(255, 255, 255, 0);
  width: 11.375rem;
  height: 15rem;
  max-height: 15rem;
  max-width: 11.375rem;
  box-shadow: 0 0.375rem 0.5rem 0 rgb(151, 146, 130);
}

.cardBack {
  border: solid black 0.125rem;
  border-radius: 0.3125rem;
  background-color: rgba(250, 250, 250, 0.459);
  // height: 12.5rem;
  width: 11.375rem;
  height: 15rem;
  max-height: 15rem;
  max-width: 11.375rem;

  text-align: center;
  display: flex;
  grid-template-rows: 4fr 1fr;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0 0.375rem 0.5rem 0 rgb(151, 146, 130);
  overflow: scroll;
}

.react-card-flip {
  border-radius: 0.3125rem;
  width: 11.375rem;
  height: 15rem;
  max-height: 15rem;
  max-width: 11.375rem;
}

.react-card-flipper {
  width: 11.375rem;
  height: 15rem;
  max-height: 15rem;
  max-width: 11.375rem;
  border-radius: 0.3125rem;
}

.showCard {
  border-radius: 0.3125rem;
  width: 11.375rem;
  height: 15rem;
  max-height: 15rem;
  max-width: 11.375rem;
 
}

.showCard:hover {
  cursor: pointer;
}


.cardTitle p {
  font-weight: bold;
}

.cardYear {
  font-style: italic;
  color:rgba(220, 20, 60, 0.692);
}

.cardType {
  font-style: italic;
  color:rgba(102, 101, 101, 0.548);
}

.cardBottom {
  background-color: rgba(250, 250, 250, 0.815);

  width: 100%;
}
}



@media (max-width: 1440px){
  .moviePosterContainer {
  
    border: solid black 0.125rem;
    border-radius: 0.3125rem;
 
    overflow: hidden;
    width: 9.375rem;
    height: 12.5rem;
    max-height: 12.5rem;
    max-width: 9.375rem;
  }
  
  
  .moviePosterContainer img {
    border-radius: 0.3125rem;
    width: 9.375rem;
    height: 12.5rem;
    max-height: 12.5rem;
    max-width: 9.375rem;
  
  }
  
  .react-card-front {
    border-radius: 0.3125rem;
    background-color: rgba(255, 255, 255, 0);
    width: 9.375rem;
    height: 12.5rem;
    max-height: 12.5rem;
    max-width: 9.375rem;
    box-shadow: 0 0.375rem 0.5rem 0 rgb(151, 146, 130);
  }
  
  .cardBack {
    border: solid black 0.125rem;
    border-radius: 0.3125rem;
    background-color: rgba(250, 250, 250, 0.459);
    width: 9.375rem;
    height: 12.5rem;
    max-height: 12.5rem;
    max-width: 9.375rem;
  
    text-align: center;
    display: flex;
    grid-template-rows: 4fr 1fr;
    justify-content: center;
    flex-direction: column;
    box-shadow: 0 0.375rem 0.5rem 0 rgb(151, 146, 130);
    overflow: scroll;
  }
  
  .react-card-flip {
    border-radius: 0.3125rem;
    width: 9.375rem;
    height: 12.5rem;
    max-height: 12.5rem;
    max-width: 9.375rem;
  }
  
  .react-card-flipper {
    width: 9.375rem;
    height: 12.5rem;
    max-height: 12.5rem;
    max-width: 9.375rem;
    border-radius: 0.3125rem;
  }
  
  
  .showCard {
    border-radius: 0.3125rem;
    width: 9.375rem;
    height: 12.5rem;
    max-height: 12.5rem;
    max-width: 9.375rem;
  
  }
  

  
  .showCard:hover {
    cursor: pointer;
  }
  
  
  .cardTitle p {
    font-weight: bold;
  }
  
  .cardYear {
    font-style: italic;
    color:rgba(220, 20, 60, 0.692);
  }
  
  .cardType {
    font-style: italic;
    color:rgba(102, 101, 101, 0.548);
  }
  
  .cardBottom {
    background-color: rgba(250, 250, 250, 0.815);
  
    width: 100%;
  }
}