@media (min-width: 1441px) {
  .home-container {
    height: 100vh;
    max-height: 100vh;
    width: 100vw;
    max-width: 100vw;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("ink-splatter6.svg");
    background-repeat: no-repeat;
    background-size: 17rem;
    background-position: 1% 112%;
  }
  .home-box {
    border-left: none;
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 5%;
    margin-left: 15rem;
    margin-right: 15rem;
    height: 38rem;
    max-height: 38rem;
    transition: all 0.5s ease-in-out;
    transform-origin: left;
  }
  .search-bar-container {
    position: relative;
    z-index: 2;
    border: rgb(245, 224, 156) solid 0.125rem;
    border-radius: 0.3125rem;
    max-height: 38.5rem;
    height: 38.5rem;
    max-width: 14.5rem;
    width: 14.5rem;
    box-shadow: 0 0.375rem 0.5rem 0 rgb(151, 146, 130);
    background-color: rgba(245, 224, 156, 0.89);
  }
  .search-bar {
    transform: rotate(90deg);
    transform-origin: top left;
    margin-left: 7.975rem;
    margin-top: 5.125rem;
    font-size: 2.5rem;
  }
  .search-bar input {
    background: transparent;
    border: none;
    border-bottom: 0.0625rem solid black;
    width: 28.125rem;
    font-family: "Roboto", sans-serif;
    color: rgb(248, 244, 231);
    text-shadow: 0.0625rem 0.0625rem 0.0625rem black;
  }
  .search-bar input::placeholder {
    color: rgba(255, 253, 246, 0.781);
    text-shadow: none;
  }
  .search-bar input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }
  .search-bar input:focus {
    outline: none;
  }
}

@media (max-width: 1440px) and (min-width: 1250px) {
  .home-container {
    height: 100vh;
    max-height: 100vh;
    width: 100vw;
    max-width: 100vw;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("ink-splatter6.svg");
    background-repeat: no-repeat;
    background-size: 15rem;
    background-position: 1% 112%;
  }
  .home-box {
    border-left: none;
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 5%;
    margin-left: 13rem;
    margin-right: 13rem;
    height: 32rem;
    max-height: 32rem;
    transition: all 0.5s ease-in-out;
    transform-origin: left;
  }
  .search-bar-container {
    position: relative;
    z-index: 2;
    border: rgb(245, 224, 156) solid 0.125rem;
    border-radius: 0.3125rem;
    max-height: 33rem;
    height: 33rem;
    max-width: 13.5rem;
    width: 13.5rem;
    box-shadow: 0 0.375rem 0.5rem 0 rgb(151, 146, 130);
    background-color: rgba(245, 224, 156, 0.89);
  }
  .search-bar {
    transform: rotate(90deg);
    transform-origin: top left;
    margin-left: 7.975rem;
    margin-top: 5.125rem;
    font-size: 2.5rem;
  }
  .search-bar input {
    background: transparent;
    border: none;
    border-bottom: 0.0625rem solid black;
    width: 23rem;
    font-family: "Roboto", sans-serif;
    color: rgb(248, 244, 231);
    text-shadow: 0.0625rem 0.0625rem 0.0625rem black;
  }
  .search-bar input::placeholder {
    color: rgba(255, 253, 246, 0.781);
    text-shadow: none;
  }
  .search-bar input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }
  .search-bar input:focus {
    outline: none;
  }
}

@media (max-width: 1249px) {
  .home-container {
    display: flex;
    height: 100vh;
    width: 100vw;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  .home-box {
    height: 35rem;
    width: 50rem;
    margin-top: 12%;
    display: grid;
    grid-template-rows: 1fr 6fr;
  }
  .search-bar-container {
    height: 6rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0.375rem 0.5rem 0 rgb(151, 146, 130);
    background-color: rgba(245, 224, 156, 0.89);
    position: relative;
    z-index: 2;
    border: rgb(245, 224, 156) solid 0.125rem;
    border-radius: 0.3125rem;
  }
  .search-bar-container input {
    height: 3rem;
    width: 30rem;
    text-align: center;
    border-radius: 0.8rem;
    font-size: 22px;
  }
  .shows {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    grid-column-gap: 3.125rem;
    overflow: scroll;
    position: relative;
    z-index: 100;
    max-height: 100vh;
    padding: 1rem;
    grid-row-gap: 1.875rem;
  }
  .shows-container {
    max-height: 35rem;
    overflow: scroll;
    width: 100%;
    height: 100%;
    max-width: 100%;
    position: relative;
    z-index: 1;
    border: #f5e09c solid 0.0625rem;
    border-left: none;
    transition: width 0s, height 2s, transform 2s;
    box-shadow: 0 0.375rem 0.5rem 0 #979282;
    border-radius: 0.3125rem;
    background-color: rgba(245, 224, 156, 0.534);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 749px) {
  .home-container {
    display: flex;
    height: 100vh;
    width: 100vw;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  .home-box {
    height: 35rem;
    width: 40rem;
    margin-top: 12%;
    display: grid;
    grid-template-rows: 1fr 6fr;
  }
  .search-bar-container {
    height: 6rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0.375rem 0.5rem 0 rgb(151, 146, 130);
    background-color: rgba(245, 224, 156, 0.89);
    position: relative;
    z-index: 2;
    border: rgb(245, 224, 156) solid 0.125rem;
    border-radius: 0.3125rem;
  }
  .search-bar-container input {
    height: 3rem;
    width: 30rem;
    text-align: center;
    border-radius: 0.8rem;
    font-size: 22px;
  }
  .shows {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    grid-column-gap: 3.125rem;
    overflow: scroll;
    position: relative;
    z-index: 100;
    max-height: 100vh;
    padding: 1rem;
    grid-row-gap: 1.875rem;
  }
  .shows-container {
    max-height: 35rem;
    overflow: scroll;
    width: 100%;
    height: 100%;
    max-width: 100%;
    position: relative;
    z-index: 1;
    border: #f5e09c solid 0.0625rem;
    border-left: none;
    transition: width 0s, height 2s, transform 2s;
    box-shadow: 0 0.375rem 0.5rem 0 #979282;
    border-radius: 0.3125rem;
    background-color: rgba(245, 224, 156, 0.534);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 599px) {
  .home-container {
    display: flex;
    height: 100vh;
    width: 100vw;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  .home-box {
    height: 39rem;
    width: 35rem;
    margin-top: 15%;
    display: grid;
    grid-template-rows: 1fr 6fr;
  }
  .search-bar-container {
    height: 6rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0.375rem 0.5rem 0 rgb(151, 146, 130);
    background-color: rgba(245, 224, 156, 0.89);
    position: relative;
    z-index: 2;
    border: rgb(245, 224, 156) solid 0.125rem;
    border-radius: 0.3125rem;
  }
  .search-bar-container input {
    height: 3rem;
    width: 20rem;
    text-align: center;
    border-radius: 0.8rem;
    font-size: 22px;
  }
  .shows {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    grid-column-gap: 3.125rem;
    overflow: scroll;
    position: relative;
    z-index: 100;
    max-height: 100vh;
    padding: 1rem;
    grid-row-gap: 1.875rem;
  }
  .shows-container {
    max-height: 35rem;
    overflow: scroll;
    width: 100%;
    height: 100%;
    max-width: 100%;
    position: relative;
    z-index: 1;
    border: #f5e09c solid 0.0625rem;
    border-left: none;
    transition: width 0s, height 2s, transform 2s;
    box-shadow: 0 0.375rem 0.5rem 0 #979282;
    border-radius: 0.3125rem;
    background-color: rgba(245, 224, 156, 0.534);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 529px) {
  .home-container {
    display: flex;
    height: 100vh;
    width: 100vw;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  .home-box {
    height: 39rem;
    width: 30rem;
    margin-top: 20%;
    display: grid;
    grid-template-rows: 1fr 6fr;
  }
  .search-bar-container {
    height: 6rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0.375rem 0.5rem 0 rgb(151, 146, 130);
    background-color: rgba(245, 224, 156, 0.89);
    position: relative;
    z-index: 2;
    border: rgb(245, 224, 156) solid 0.125rem;
    border-radius: 0.3125rem;
  }
  .search-bar-container input {
    height: 3rem;
    width: 20rem;
    text-align: center;
    border-radius: 0.8rem;
    font-size: 22px;
  }
  .shows {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    grid-column-gap: 3.125rem;
    overflow: scroll;
    position: relative;
    z-index: 100;
    max-height: 100vh;
    padding: 1rem;
    grid-row-gap: 1.875rem;
  }
  .shows-container {
    max-height: 35rem;
    overflow: scroll;
    width: 100%;
    height: 100%;
    max-width: 100%;
    position: relative;
    z-index: 1;
    border: #f5e09c solid 0.0625rem;
    border-left: none;
    transition: width 0s, height 2s, transform 2s;
    box-shadow: 0 0.375rem 0.5rem 0 #979282;
    border-radius: 0.3125rem;
    background-color: rgba(245, 224, 156, 0.534);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 499px) {
  .home-container {
    display: flex;
    height: 100vh;
    width: 100vw;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  .home-box {
    height: 39rem;
    width: 27rem;
    margin-top: 20%;
    display: grid;
    grid-template-rows: 1fr 6fr;
  }
  .search-bar-container {
    height: 6rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0.375rem 0.5rem 0 rgb(151, 146, 130);
    background-color: rgba(245, 224, 156, 0.89);
    position: relative;
    z-index: 2;
    border: rgb(245, 224, 156) solid 0.125rem;
    border-radius: 0.3125rem;
  }
  .search-bar-container input {
    height: 3rem;
    width: 20rem;
    text-align: center;
    border-radius: 0.8rem;
    font-size: 22px;
  }
  .shows {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    grid-column-gap: 3.125rem;
    overflow: scroll;
    position: relative;
    z-index: 100;
    max-height: 100vh;
    padding: 1rem;
    grid-row-gap: 1.875rem;
  }
  .shows-container {
    max-height: 35rem;
    overflow: scroll;
    width: 100%;
    height: 100%;
    max-width: 100%;
    position: relative;
    z-index: 1;
    border: #f5e09c solid 0.0625rem;
    border-left: none;
    transition: width 0s, height 2s, transform 2s;
    box-shadow: 0 0.375rem 0.5rem 0 #979282;
    border-radius: 0.3125rem;
    background-color: rgba(245, 224, 156, 0.534);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 459px) {
  .home-container {
    display: flex;
    height: 100vh;
    width: 100vw;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  .home-box {
    height: 39rem;
    width: 25rem;
    margin-top: 20%;
    display: grid;
    grid-template-rows: 1fr 6fr;
  }
  .search-bar-container {
    height: 6rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0.375rem 0.5rem 0 rgb(151, 146, 130);
    background-color: rgba(245, 224, 156, 0.89);
    position: relative;
    z-index: 2;
    border: rgb(245, 224, 156) solid 0.125rem;
    border-radius: 0.3125rem;
  }
  .search-bar-container input {
    height: 3rem;
    width: 20rem;
    text-align: center;
    border-radius: 0.8rem;
    font-size: 22px;
  }
  .shows {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    grid-column-gap: 3.125rem;
    overflow: scroll;
    position: relative;
    z-index: 100;
    max-height: 100vh;
    padding: 1rem;
    grid-row-gap: 1.875rem;
  }
  .shows-container {
    max-height: 35rem;
    overflow: scroll;
    width: 100%;
    height: 100%;
    max-width: 100%;
    position: relative;
    z-index: 1;
    border: #f5e09c solid 0.0625rem;
    border-left: none;
    transition: width 0s, height 2s, transform 2s;
    box-shadow: 0 0.375rem 0.5rem 0 #979282;
    border-radius: 0.3125rem;
    background-color: rgba(245, 224, 156, 0.534);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 399px) {
  .home-container {
    display: flex;
    height: 100vh;
    width: 100vw;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  .home-box {
    height: 39rem;
    width: 22rem;
    margin-top: 20%;
    display: grid;
    grid-template-rows: 1fr 6fr;
  }
  .search-bar-container {
    height: 6rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0.375rem 0.5rem 0 rgb(151, 146, 130);
    background-color: rgba(245, 224, 156, 0.89);
    position: relative;
    z-index: 2;
    border: rgb(245, 224, 156) solid 0.125rem;
    border-radius: 0.3125rem;
  }
  .search-bar-container input {
    height: 3rem;
    width: 20rem;
    text-align: center;
    border-radius: 0.8rem;
    font-size: 22px;
  }
  .shows {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    grid-column-gap: 3.125rem;
    overflow: scroll;
    position: relative;
    z-index: 100;
    max-height: 100vh;
    padding: 1rem;
    grid-row-gap: 1.875rem;
  }
  .shows-container {
    max-height: 35rem;
    overflow: scroll;
    width: 100%;
    height: 100%;
    max-width: 100%;
    position: relative;
    z-index: 1;
    border: #f5e09c solid 0.0625rem;
    border-left: none;
    transition: width 0s, height 2s, transform 2s;
    box-shadow: 0 0.375rem 0.5rem 0 #979282;
    border-radius: 0.3125rem;
    background-color: rgba(245, 224, 156, 0.534);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 349px) {
  .home-container {
    display: flex;
    height: 100vh;
    width: 100vw;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  .home-box {
    height: 39rem;
    width: 20rem;
    margin-top: 22%;
    display: grid;
    grid-template-rows: 1fr 6fr;
  }
  .search-bar-container {
    height: 6rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0.375rem 0.5rem 0 rgb(151, 146, 130);
    background-color: rgba(245, 224, 156, 0.89);
    position: relative;
    z-index: 2;
    border: rgb(245, 224, 156) solid 0.125rem;
    border-radius: 0.3125rem;
  }
  .search-bar-container input {
    height: 3rem;
    width: 15rem;
    text-align: center;
    border-radius: 0.8rem;
    font-size: 20px;
  }
  .shows {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    grid-column-gap: 3.125rem;
    overflow: scroll;
    position: relative;
    z-index: 100;
    max-height: 100vh;
    padding: 1rem;
    grid-row-gap: 1.875rem;
  }
  .shows-container {
    max-height: 35rem;
    overflow: scroll;
    width: 100%;
    height: 100%;
    max-width: 100%;
    position: relative;
    z-index: 1;
    border: #f5e09c solid 0.0625rem;
    border-left: none;
    transition: width 0s, height 2s, transform 2s;
    box-shadow: 0 0.375rem 0.5rem 0 #979282;
    border-radius: 0.3125rem;
    background-color: rgba(245, 224, 156, 0.534);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 320px) {
  .home-container {
    display: flex;
    height: 100vh;
    width: 100vw;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  .home-box {
    height: 30rem;
    width: 20rem;
    margin-top: 25%;
    display: grid;
    grid-template-rows: 1fr 6fr;
  }
  .search-bar-container {
    height: 6rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0.375rem 0.5rem 0 rgb(151, 146, 130);
    background-color: rgba(245, 224, 156, 0.89);
    position: relative;
    z-index: 2;
    border: rgb(245, 224, 156) solid 0.125rem;
    border-radius: 0.3125rem;
  }
  .search-bar-container input {
    height: 3rem;
    width: 15rem;
    text-align: center;
    border-radius: 0.8rem;
    font-size: 20px;
  }
  .shows {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    grid-column-gap: 3.125rem;
    overflow: scroll;
    position: relative;
    z-index: 100;
    max-height: 100vh;
    padding: 1rem;
    grid-row-gap: 1.875rem;
  }
  .shows-container {
    max-height: 35rem;
    overflow: scroll;
    width: 100%;
    height: 100%;
    max-width: 100%;
    position: relative;
    z-index: 1;
    border: #f5e09c solid 0.0625rem;
    border-left: none;
    transition: width 0s, height 2s, transform 2s;
    box-shadow: 0 0.375rem 0.5rem 0 #979282;
    border-radius: 0.3125rem;
    background-color: rgba(245, 224, 156, 0.534);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
