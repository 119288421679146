.app-container {
  background-color: whitesmoke !important;

  min-height: 100vh;
  width: 100vw;
}

.body {
  background: whitesmoke !important;
}
