h1 {
  margin: 0;
}

body {
  margin: 0;
  background-color: rgb(255, 247, 220);
}

body ::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}

body ::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0) !important;
}

body ::-webkit-scrollbar {
  width: 0px !important;
}

.no-route {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-route p {
  font-size: 2rem;
  font-family: "Julee";
}
